import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../../components/layouts/layout'

const IndexPage = () => (
  <Layout>
  	<Container className="py-5">

		<h1 className="text-center mt-5">Mentions légales - RGPD</h1>
		<h2 className="font-dosis text-center mt-3 mb-5">Chartre de protection des données personnelles</h2>

		<Row>
			<Col className="mt-5" sm="12" lg={{size: 10, offset: 1}}>
				
				<h2 className="font-dosis">Article 1 – Définitions des termes utilisés dans la charte</h2>
				<p>
					On désignera par la suite :<br/>
					<ul>
						<li>« Données personnelles » : se définit comme « toute information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres », conformément à la loi Informatique et libertés du 6 janvier 1978.</li>
						<li>« Site » ou « Service » : le site laboiteaoutils.net et l’ensemble de ses pages.</li>
						<li>« Editeur » : Vincent RICHARD, personne physique responsable de l’édition et du contenu du Site.</li>
						<li>« Utilisateur » : l’internaute visitant et utilisant les Services du Site.</li>
					</ul>
					<br/>
				</p>

				<h2 className="font-dosis">Article 2 – Introduction et rôle de la charte</h2>
				<p>
					La présente charte vise à vous informer des engagements du Site eu égard au respect de votre vie privée et à la protection des Données personnelles vous concernant, collectées et traitées à l’occasion de votre utilisation du service.
					<br/>
					En vous inscrivant sur le Site, vous vous engagez à nous fournir des informations véritables vous concernant. La communication de fausses informations est contraire aux conditions générales figurant sur le Site.
					<br/>
					Vous pouvez à n’importe quel moment faire une demande auprès de laboiteaoutils.net afin de savoir quelles informations vous concernant sont détenues par Epernot.com, de vous opposer à leur traitement, de les faire modifier ou supprimer et ce, en contactant le directeur de publication via le formulaire de contact.
					<br/><br/>
				</p>

				<h2 className="font-dosis">Article 3 – Données collectées sur le Site</h2>
				<p>
					Les Données collectées et ultérieurement traitées par le Site sont celles que vous nous transmettez volontairement en remplissant les différents formulaires présents au sein du Site. Pour certaines opérations sur les contenus, vous pourrez être amenés à transmettre des Données vous concernant à des tiers partenaires au travers de leurs propres services, plus spécifiquement lors des paiements que vous pourrez effectuer. Nous ne disposerons pas desdites données, leur collecte et leur traitement étant régis par les conditions propres à ces intervenants. Nous vous invitons à consulter leurss conditions avant de communiquer vos Données dans ce cadre.
					<br/><br/>
					Votre adresse IP (numéro d’identification attribué sur Internet à votre ordinateur) est collectée automatiquement. Vous êtes informés que le service est susceptible de mettre en œuvre un procédé automatique de traçage (Cookie), auquel vous pouvez faire obstacle en modifiant les paramètres concernés de votre navigateur internet, comme expliqué dans les conditions générales du présent Site.
					<br/><br/>
					Les coordonnées des Utilisateurs du Site qui se seront inscrits sur celui-ci seront sauvegardées, dans le respect des dispositions de la loi informatique et liberté du 6 janvier 1978. Conformément à cette dernière, ils disposent d’un droit d’accès, de retrait, de modification ou de rectification des Données qu’ils ont fournies. Pour cela, il leur suffit d’en faire la demande via le formulaire de contact.
					<br/><br/>
				</p>

				<h2 className="font-dosis">Article 4 – Finalités des Données collectées</h2>
				<p>
					Les Données identifiées comme étant obligatoires sur les formulaires du Site sont nécessaires afin de pouvoir bénéficier des fonctionnalités correspondantes du Site, et plus spécifiquement des opérations sur les contenus proposés au sein de celui-ci.
					<br/>
					Le Site collecte et traite les Données de ses Utilisateurs pour envoyer des mails, effectuer des statistiques de trafic et traiter les commandes sur le Site.
					<br/><br/>
				</p>

				<h2 className="font-dosis">Article 5 – Destinataires et utilisation des Données collectées</h2>
				<p>
					Les Données collectées par nos soins sont traitées pour les besoins d’exécution des opérations sur les contenus du Service.
					<br/>
					Vous êtes susceptible de recevoir des courriers électroniques de notre Service, notamment dans le cadre de newsletters que vous avez acceptées. Vous pouvez demander à ne plus recevoir ces courriers électroniques en nous contactant via le formulaire de contact ou sur le lien prévu à cet effet dans chacun des courriers électroniques qui vous seront adressés.
					<br/><br/>
				</p>

				<h2 className="font-dosis">Article 6 – Sécurité des Données</h2>
				<p>
					Vous êtes informés que vos Données pourront être divulguées en application d’une loi, d’un règlement ou en vertu d’une décision d’une autorité réglementaire ou judiciaire compétente ou encore, si cela s’avère nécessaire, aux fins, pour l’Editeur, de préserver ses droits et intérêts.
					<br/><br/>
				</p>

				<h2 className="font-dosis">Article 7 – Durée de conservation des Données</h2>
				<p>
					Les Données sont stockées chez l’hébergeur du site, dont les coordonnées figurent dans les mentions légales du Site, et sont conservées pour la durée strictement nécessaire à la réalisation des finalités visées ci-avant. Au-delà de cette durée, elles seront conservées à des fins exclusivement statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.
					<br/><br/>
				</p>

			</Col>
		</Row>
  	</Container>

     
  </Layout>
)

export default IndexPage
